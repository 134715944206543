import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  styled,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "src/store";
import * as Yup from "yup";
import { FC, useEffect, useState } from "react";
import { GrayButton } from "src/components/GrayButton";
import { StyledLoadingButton } from "src/components/LoadingButton";
import colors from "src/theme/colors";
import FormInputLable from "src/components/FormInputLabel";
import { StyledTextField } from "src/components/Input";
import { downloadURLFile } from "src/utils/downloadURLFile";
import { confirmTruck } from "../../redux/TruckSlice";
import {
  getorders,
  reset as resetOrders,
} from "src/features/orders/redux/orderSlice";

interface WeightBridgeViewModal {
  warehouseId: number;
  data: any;
  vendors: any;
  open: boolean;
  loading: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

export const WeightBridgeViewModal: FC<WeightBridgeViewModal> = (props) => {
  const { warehouseId, data, vendors, open, loading, onConfirm, onDismiss } =
    props;

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const [image, setImage] = useState("");
  const [netWeightValue, setNetWeightValue] = useState(
    Number(data?.net_weight)
  );

  //formik---------------------------------
  const ItemSchema = Yup.object().shape({
    vendor: Yup.string().nullable(),
    order: Yup.string().nullable(),
    fullWeight: Yup.string(),
    emptyWeight: Yup.string(),
    numberplate: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      vendor: data?.vendor?.id,
      order: data?.order?.id,
      fullWeight: data?.full_weight * 1000,
      emptyWeight: data?.empty_weight * 1000,
      numberplate: data?.numberplate,
    },
    validationSchema: ItemSchema,

    onSubmit: async (values: any) => {
      const patchValue = {
        warehouseId: warehouseId,
        truckId: Number(data?.id),
        vendor_id: values.vendor,
        order_id: values.order,
        full_weight: values?.fullWeight / 1000,
        empty_weight: values?.emptyWeight / 1000,
        numberplate: values?.numberplate,
        driver_license_number: data?.driver_license_number,
      };

      dispatch(confirmTruck(patchValue)).then(() => onConfirm());
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    getFieldProps,
  } = formik;

  const { orders, isLoading: isOrdersLoading } = useSelector(
    (state: RootState) => state.orders
  );

  useEffect(() => {
    const fullWeight = Number(data?.full_weight * 1000);
    const emptyWeight = Number(data?.empty_weight * 1000);
    setNetWeightValue(fullWeight - emptyWeight);
    setImage(data?.weighbridghe);
    setFieldValue("vendor", data?.vendor?.id);
    setFieldValue("order", data?.order?.id);
    setFieldValue("fullWeight", data?.full_weight * 1000);
    setFieldValue("emptyWeight", data?.empty_weight * 1000);
    setFieldValue("numberplate", data?.numberplate);

    if (data?.vendor?.id) {
      dispatch(getorders({ limit: 100, page: 1, vendor_id: data?.vendor?.id }));
    }
  }, [data]);

  useEffect(() => {
    const fullWeight = Number(values?.fullWeight);
    const emptyWeight = Number(values?.emptyWeight);
    setNetWeightValue(fullWeight - emptyWeight);
  }, [values]);

  useEffect(() => {
    setFieldValue("order", "");
    if (values?.vendor) {
      dispatch(getorders({ limit: 100, page: 1, vendor_id: values?.vendor }));
    }
  }, [values?.vendor]);

  const onClose = () => {
    resetForm();
    resetOrders();
    onDismiss();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>
        <Content>
          <Title>Weight Bridge</Title>
          <ImageBox>
            <Image
              style={{ backgroundImage: `url(${image ?? data?.weighbridghe})` }}
            />
          </ImageBox>

          <FormTitle>Weighbridge information</FormTitle>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Row style={{ marginBottom: "28px" }}>
                <Col>
                  <Field>
                    <FormInputLable noPrefix>Vendor</FormInputLable>
                    <StyledTextField
                      select
                      placeholder="Select a Vendor"
                      fullWidth
                      size="small"
                      {...getFieldProps("vendor")}
                      error={Boolean(touched.vendor && errors.vendor)}
                      helperText={
                        (touched.vendor as any) && (errors.vendor as any)
                      }
                      onChange={formik.handleChange("vendor")}
                      SelectProps={{
                        multiple: false,
                      }}
                    >
                      {vendors?.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </StyledTextField>
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <FormInputLable noPrefix>Purchase Order</FormInputLable>
                    <StyledTextField
                      select
                      fullWidth
                      disabled={isOrdersLoading || !values?.vendor}
                      size="small"
                      {...getFieldProps("order")}
                      sx={{ maxHeight: 200, overflow: "auto" }}
                      error={Boolean(touched.order && errors.order)}
                      helperText={
                        (touched.order as any) && (errors.order as any)
                      }
                    >
                      {orders?.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.code}
                          </MenuItem>
                        );
                      })}
                    </StyledTextField>
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Weights>
                    <Row>
                      <Col>
                        <Field>
                          <FormInputLable noPrefix>Full Weight</FormInputLable>
                          <StyledTextField
                            placeholder="Full Weight"
                            fullWidth
                            size="small"
                            {...getFieldProps("fullWeight")}
                            error={Boolean(
                              touched.fullWeight && errors.fullWeight
                            )}
                            helperText={
                              (touched.fullWeight as any) &&
                              (errors.fullWeight as any)
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Kg
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Field>
                      </Col>
                      <Col>
                        <Field>
                          <FormInputLable noPrefix>Empty Weight</FormInputLable>
                          <StyledTextField
                            placeholder="Empty Weight"
                            fullWidth
                            size="small"
                            {...getFieldProps("emptyWeight")}
                            error={Boolean(
                              touched.emptyWeight && errors.emptyWeight
                            )}
                            helperText={
                              (touched.emptyWeight as any) &&
                              (errors.emptyWeight as any)
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Kg
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Field>
                      </Col>
                    </Row>
                    <NetWight>
                      Net Weight: <b>{netWeightValue} KG</b>
                    </NetWight>
                  </Weights>
                </Col>
                <Col>
                  <Field>
                    <FormInputLable noPrefix>Truck Number</FormInputLable>
                    <StyledTextField
                      placeholder="Truck Number"
                      fullWidth
                      size="small"
                      {...getFieldProps("numberplate")}
                      error={Boolean(touched.numberplate && errors.numberplate)}
                      helperText={
                        (touched.numberplate as any) &&
                        (errors.numberplate as any)
                      }
                    />
                  </Field>
                </Col>
              </Row>

              <Actions>
                <IconButton>
                  <DownloadBtn
                    onClick={() =>
                      downloadURLFile(data?.weighbridghe, "weighbridghe", () =>
                        enqueueSnackbar("Failed to download Image", {
                          variant: "error",
                        })
                      )
                    }
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/icons/DownloadBlue.svg`
                      }
                    />
                  </DownloadBtn>
                </IconButton>
                <Buttons>
                  <CancelButtonBox>
                    <GrayButton
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled={loading}
                      onClick={onClose}
                    >
                      Cancel
                    </GrayButton>
                  </CancelButtonBox>
                  <SubmitButtonBox>
                    <StyledLoadingButton
                      variant="contained"
                      fullWidth
                      size="small"
                      loading={loading}
                      color="secondary"
                      type="submit"
                      //   onClick={onSave}
                    >
                      Save And Close
                    </StyledLoadingButton>
                  </SubmitButtonBox>
                </Buttons>
              </Actions>
            </Form>
          </FormikProvider>
        </Content>

        <CloseIcon onClick={() => onClose()}>
          <img src={process.env.PUBLIC_URL + `/assets/icons/${"close"}.svg`} />
        </CloseIcon>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  min-width: 800px;
  max-height: 90vh;
  overflow: auto;
  height: fit-content;
  background-color: ${colors.base.white};
  border: 1px solid ${colors.gray[200]};
  border-radius: 5px;
  padding: 42px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 90%;
    min-width: auto;
  }
`;

const Content = styled(Box)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  & > form {
    width: 100%;
  }
`;

const Title = styled("h6")`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 28px;
  text-align: center;
  color: ${colors.gray[850]};
`;

const FormTitle = styled("div")`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  padding-top: 8px;
  margin: 16px 0;
  text-align: left;
  border-top: 1px solid ${colors.gray[100]};
  color: ${colors.gray[850]};
`;

const NetWight = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: ${colors.gray[600]};
  & > b {
    font-size: 16px;
    font-weight: 700;
    color: ${colors.gray[850]};
  }
`;

const ImageBox = styled("div")`
  width: 690px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: 1px dashed ${colors.gray[300]};
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
  @media screen and (max-width: 720px) {
    height: 340px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: 280px;
  }
  @media screen and (max-width: 500px) {
    height: 250px;
  }
  @media screen and (max-width: 400px) {
    height: 200px;
  }
`;

const Image = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 5;
`;

const Row = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Col = styled("div")`
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Field = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Weights = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Actions = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }
`;

const DownloadBtn = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Buttons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

const CancelButtonBox = styled(Box)`
  margin-right: 18px;
`;

const SubmitButtonBox = styled(Box)``;

const CloseIcon = styled(Box)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  cursor: pointer;
`;
